<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      formFunctionCode: 'districtArea-add',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'email') {
        item.props = {
          ...item.props,
          maxlength: 6,
        };
        item.validate = [
          {
            trigger: 'blur',
            pattern: /^\d+(\.\d+)?$/,
            message: '请输入6位数字',
          },
          {
            trigger: 'blur',
            len: 6,
            message: '请输入6位数字',
          },
        ];
      }
      if (item.field === 'latitude') {
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            pattern: /^\d+(\.\d+)?$/,
            message: '仅能输入数字',
          },
        ];
      }
      if (item.field === 'longitude') {
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            pattern: /^\d+(\.\d+)?$/,
            message: '仅能输入数字',
          },
        ];
      }
      if (item.field === 'regionCode') {
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      }
      if (item.field === 'parentCode') {
        item.restful = '/mdm/mdmRegionController/regionSelect';
        item.optionsKey = { label: 'unionName', value: 'regionCode' };
        item.restfulParamsGetValue = { abc: 'regionLevel' };// 下拉框的参数需取其他字段的时候这样设置
        item.restfulParams = { regionLevel: '0' };
        item.refresh = true;
        item.props.remoteParams = 'unionName';
        item.props.remote = true;
        item.props.filterable = true;
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      const parentCode = this.getRule('parentCode');
      const RegionLevel = this.getRule('regionLevel');
      RegionLevel.on = {
        ...RegionLevel.on,
        change: (val) => {
          console.log(val);
          parentCode.restfulParams = {
            regionLevel: this.getFieldValue('regionLevel') - 1,
          };
          if (this.getFieldValue('parentCode')) {
            this.setValue({ parentCode: '' });
          }
        },
      };
      if (this.formConfig.type !== 'add') {
        this.updateRules({
          regionCode: {
            props: {
              disabled: true,
            },
          },
        });
      }
      if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
        request
          .get('/mdm/mdmRegionController/query', { regionCode: this.formConfig.id })
          .then((res) => {
            if (res.success) {
              this.row = res.result;
              this.setValue(res.result);
              parentCode.restfulParams = { regionLevel: res.result.regionLevel - 1 };
              this.reload();
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/mdm/mdmRegionController/save';
        const params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmRegionController/update';
          params.id = this.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
