<script>
import TablePage from '../../../../components/table_page';
import Modal from '../../../../components/modal';
import Form from './form';
// import request from '../../../../utils/request';

export default {
  name: 'proportion',
  extends: TablePage,
  data() {
    return {
      // requestUrl: '/mdm/mdmRegionController/list',
      requestUrl: '/mdm/mdmRegionController/pageList',
      configs: {
        tableConfig: {
          border: true,
          treeConfig: {
            lazy: true,
          },
          idKey: 'id', // 默认勾选判断字段
          resizable: false, // 所有的列是否允许拖动列宽调整大小
          showOverflow: true, // 当内容过长时显示为省略号
          keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
          highlightHoverRow: true, // 鼠标滑动高亮
        },
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      params: {
        regionLevel: '0',
      },
    };
  },
  components: {
    Modal,
    Form,
  },

  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.height = '360px';
        this.modalConfig.width = '1000px';
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.modalConfig.height = '360px';
        this.modalConfig.width = '1000px';
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.height = '360px';
        this.modalConfig.width = '1000px';
        this.formConfig.buttonCode = 'view';
        this.openModal();
      } else if (val.code === 'head-import' || val.code === 'head-export') {
        return;
      }

      this.formConfig = {
        type: val.code,
        code: val.code,
        id: val.code !== 'head-add' ? row.regionCode : null,
      };
    },
    // 异步加载前置
    loadChildBefore({ row }) {
      return {
        parentCode: row.regionCode,
      };
    },
    // 搜索前置方法
    beforeGetList() {
      if (this.formData.regionCode || this.formData.regionName) {
        this.params = {};
      }
      return true;
    },
    setColumn(item) {
      const copyItem = item;
      if (copyItem.field === 'createDate') {
        copyItem.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond ? row.createDateSecond : ''}`;
      }
      if (copyItem.field === 'updateDate') {
        copyItem.formatter = ({ row }) => (row.updateDate === null ? '' : `${row.updateDate} ${row.updateDateSecond ? row.updateDateSecond : ''}`);
      }
      if (copyItem.field === 'regionName') {
        copyItem.treeNode = true;
      }
      return copyItem;
    },
    // // 删除操作
    // deleteClick({ row }) {
    //   const ids = row ? [row.id] : this.selection;

    //   const title = '此操作将永久删除此数据, 是否继续?';
    //   const url = '/mdm/mdmRegionController/delete';
    //   const msg = '删除';

    //   const data = {
    //     params: ids,
    //     url,
    //     title,
    //     msg,
    //   };
    //   this.messageBox(data);
    // },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('mdm_districtArea');
    // columns.map((item) => {
    //   const copyItem = item;
    //   if (copyItem.field === 'regionName') {
    //     copyItem.treeNode = true;
    //   }
    //   return copyItem;
    // });
    // this.configs.columns = null;
    // setTimeout(() => {
    //   this.configs.columns = columns;
    // }, 1);
  },
  // methods: {

  // },
};
</script>
